import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../Components/TableContainer";
import DUMMY_USER from "../../Assets/loginimages/logo.png";
import CustomAction from "../../Components/CustomAction";
import crossIcon from "../../Assets/common/crossIcon.png";
import CorrectIcon from "../../Assets/common/Correcticon.png";
import ViewBusinessDetailModal from "./ViewBusinessDetailModal";
import CustomDropDown from "../../Components/CustomDropDown";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Services/Constant";
const BusinessManagement = () => {
  const [openBusinessDetailsModal, setOpenBusinessDetailsModal] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBusinessCategory, setSelectedBusinessCategory] =
    useState(null);
  const [getBusiness, setGetBusiness] = useState([]);
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessCategoryList, setBusinessCategoryList] = useState([]);
  const [businessDetails, setBusinessDetails] = useState({});
  const handlePageChange = (newValue) => {
    setPage(newValue);
  };
  const columns = [
    { field: "rowid", headerName: "Sr.No", width: 100 },
    {
      field: "thumb",
      width: 200,
      headerName: "Profile Photo",
      renderCell: (params) => {
        const { business_media } = params?.row;

        return (
          <img
            src={
              business_media?.length > 0 && business_media[0]?.thumb
                ? `${BASE_URL}${business_media[0]?.thumb}`
                : DUMMY_USER
            }
            alt="Profile"
            style={{ width: "40px", height: "40px", borderRadius: "5px" }}
          />
        );
      },
    },
    {
      field: "phno_cc",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{`${e?.row?.phno_cc} ${e?.row?.phno}`}</Typography>
          </Box>
        );
      },
    },
    {
      field: "owner_name",
      headerName: "Owner Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "business_name",
      headerName: "Business Name",
      flex: 1,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      minWidth: 200,
    },
    {
      field: "category_name",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "approve_status",
      headerName: "Status",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (e) => {
        const { status } = e.row;
        const statusStyle = {
          fontWeight: "500",
          // opacity: "0.7",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };

        const boxStyle = {
          width: "100px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        };
        if (e?.row?.approve_status === 1) {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  background: "green",
                  color: "white",
                }}
              >
                Approved
              </span>
            </Box>
          );
        } else if (e?.row?.approve_status === 0) {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                // gap: "1.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  // gap: "1.5rem",
                }}
              >
                <IconButton
                  onClick={() => handleAcceptReject(e?.row?.id, 1)}
                  sx={{
                    width: "45px",
                    height: "45px",
                  }}
                >
                  <img
                    src={CorrectIcon}
                    alt="user"
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => handleAcceptReject(e?.row?.id, -1)}
                  sx={{
                    width: "45px",
                    height: "45px",
                  }}
                >
                  <img
                    src={crossIcon}
                    alt="user"
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </IconButton>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  background: "#D00C00",
                  color: "white",
                }}
              >
                Rejected
              </span>
            </Box>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 80,
      renderCell: (params) => (
        <Box
          onClick={() => handleView(params?.row)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <CustomAction showView={true} />
        </Box>
      ),
    },
  ];
  const handleAcceptReject = async (id, value) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        value === -1
          ? "You want to reject this business"
          : "You want to approve this business",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append("approve_status", value);
          formData.append("id", id);
          const sendData = await AxiosInstance.post(
            ApiList.UPDATE_BUSINESS,
            formData
          );
          if (sendData?.data?.s) {
            getBusinessList();
            toast.success(sendData?.data?.m);
          }
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    });
  };
  const handleView = (elm) => {
    setOpenBusinessDetailsModal(true);
    setBusinessDetails(elm);
  };
  const getBusinessList = async () => {
    try {
      setIsLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_BUSINESS_LIST, {
        params: {
          count: page * pageSize,
          offset: pageSize,
          approve_status: selectedCategory,
          category_ids: selectedBusinessCategory,
        },
      });
      if (res && res?.data?.s === 1) {
        const formattedUsers = res?.data?.r?.map((user, index) => ({
          rowid: index + 1 + page * pageSize,
          ...user,
        }));
        setGetBusiness(formattedUsers);
        setCount(res?.data?.c);
      } else {
        setGetBusiness([]);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error(error);
      setLoading(false);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  const getCategory = async () => {
    try {
      const fetchFeed = await AxiosInstance.get(ApiList.GET_BUSINESS_CATEGORY);
      if (fetchFeed?.data?.s) {
        const formattedUsers = fetchFeed?.data?.r;
        const All = {
          id: null,
          name: "All",
        };
        setBusinessCategoryList([All, ...formattedUsers]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getBusinessList();
    getCategory();
  }, [selectedCategory, page, selectedBusinessCategory]);
  const statusList = [
    { id: null, name: "All" },
    { id: 0, name: "Pending" },
    { id: 1, name: "Approved" },
    { id: -1, name: "Rejected" },
  ];

  return (
    <>
      <Box sx={{ width: "100%", px: 2, py: 2 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            // flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "22px", md: "32px" }, fontWeight: "500" }}
            >
              Business Management
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              gap: "20px",
            }}
          >
            <CustomDropDown
              title={"Status"}
              menuList={statusList}
              onChange={(e) => setSelectedCategory(e?.target?.value)}
              value={selectedCategory}
              sx={{
                border: "none",
                background: "#EFFAFF",
                borderRadius: "6px",
              }}
              titlesx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            />
            <CustomDropDown
              value={selectedBusinessCategory}
              title={"Category"}
              menuList={businessCategoryList}
              onChange={(e) => setSelectedBusinessCategory(e?.target?.value)}
              sx={{
                border: "none",
                background: "#EFFAFF",
                borderRadius: "6px",
              }}
              titlesx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 3, height: "500px" }}>
          <TableContainer
            sx={{ height: { xs: "300px", md: "350px", xl: "600px" } }}
            rows={getBusiness}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            hideFooter={true}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
      <ViewBusinessDetailModal
        open={openBusinessDetailsModal}
        onClose={() => setOpenBusinessDetailsModal(false)}
        close={() => setOpenBusinessDetailsModal(false)}
        businessDetails={businessDetails}
        handleAcceptReject={handleAcceptReject}
      />
    </>
  );
};

export default BusinessManagement;
