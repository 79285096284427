import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import TableContainer from "../../Components/TableContainer";
import Profile from "../../Assets/profile1.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomAction from "../../Components/CustomAction";
import { BASE_URL } from "../../Services/Constant";

const ReportedJokes = ({ data, setData, pageSize, page, count, setPage }) => {
  const navigate = useNavigate();
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleDeletePartner = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Reported Joke",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          feed_id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.DELETE_FEED,
            params
          );
          if (response?.data?.s === 1) {
            setData((prevData) => prevData.filter((feed) => feed.id !== id));
            toast.success("Joke Deleted Sucessfully !.");
          }
        } catch (error) {
          console.log(error);
          toast.error("Failed to delete Joke. Please try again.");
        }
      }
    });
  };

  const columns = [
    { field: "rowid", headerName: "Sr.No", width: 100 },
    {
      field: "profile_img",
      headerName: "Profile Photo",
      width: 200,
      renderCell: (params) => {
        const { user_details } = params?.row;
        return (
          <img
            src={
              user_details && user_details?.profile_img
                ? `${BASE_URL}${user_details?.profile_img}`
                : Profile
            }
            alt="Profile"
            style={{ width: "40px", height: "40px", borderRadius: "5px" }}
          />
        );
      },
    },
    {
      field: "full_name",
      headerName: "Full name",
      width: 200,
      renderCell: (params) => {
        const { user_details } = params?.row;
        return <Typography>{user_details?.full_name}</Typography>;
      },
    },
    {
      field: "caption",
      headerName: "Caption",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        const subtitle = params?.row?.caption || "--";
        const truncatedSubtitle =
          subtitle.length > 50 ? `${subtitle.substring(0, 50)}...` : subtitle;

        return (
          <Tooltip title={subtitle}>
            <span>{truncatedSubtitle}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "report_count",
      headerName: "No. of reports",
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              p: 0,
              m: 0,
              height: "100%",
            }}
          >
            <IconButton
              onClick={() =>
                navigate("/reportedfeed/reportedjokesdetails", {
                  state: {
                    id: params?.row?.id,
                  },
                })
              }
            >
              <VisibilityIcon sx={{ color: "#3BC6F1" }} />
            </IconButton>

            <CustomAction
              showDelete={true}
              isDelete={() => handleDeletePartner(params?.row?.id)}
            />
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={{ width: "100%", p: 2 }}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TableContainer
            sx={{ height: { xs: "300px", md: "300px", xl: "600px" } }}
            rows={data}
            columns={columns}
            pageSize={pageSize}
            page={page}
            totalCount={count}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default ReportedJokes;
