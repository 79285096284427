import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import sampleImage from "../../Assets/common/Rectangle 8.png";
import { BASE_URL } from "../../Services/Constant";
const ViewBusinessDetailModal = (props) => {
  const {
    open,
    onClose,
    close,
    onSubmit,
    businessDetails,
    handleAcceptReject,
  } = props;
  const { business_media } = businessDetails;
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "40px",
            p: 2,

            overflow: "scroll",
          },
          "& .MuiDialogContent-root": {
            overflowY: "initial",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            pt: 2,
            pl: 2,
            width: "100%",
            alignItems: "center",
            borderRadius: "40px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Business Details
            </Typography>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <DialogContent sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              width: "100%",
              pt: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "30px",
                flexDirection: { xs: "row", md: "row" },
              }}
            >
              <Box sx={{ width: "300px", height: "300px" }}>
                <img
                  src={
                    business_media?.length > 0 && business_media[0]?.media
                      ? `${BASE_URL}${business_media[0]?.media}`
                      : sampleImage
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt=""
                />
              </Box>
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#959595",
                    }}
                  >
                    Business Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    {businessDetails?.business_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#959595",
                    }}
                  >
                    Owner Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    {businessDetails?.owner_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#959595",
                    }}
                  >
                    Category
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    {businessDetails?.category_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#959595",
                    }}
                  >
                    Location
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    {businessDetails?.location}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: "#959595" }}
              >
                Description
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: "#000000" }}
              >
                {businessDetails?.description}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  py: 2,
                  px: 6,
                  borderRadius: "18px",
                  bgcolor:
                    businessDetails?.approve_status === 1
                      ? "#309C3B"
                      : businessDetails?.approve_status === -1
                      ? "#E13A3A"
                      : "orange",
                  color: "white",
                  textTransform: "none",
                }}
              >
                {businessDetails?.approve_status === 1
                  ? "Approved"
                  : businessDetails?.approve_status === -1
                  ? "Rejected"
                  : "Pending"}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewBusinessDetailModal;
