import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import TermsConditions from "./TermsConditions";
import TopTitle from "../../Components/TopTitle";
import PrivacyPolicy from "./PrivacyPolicy";
// import AboutUs from "./AboutUs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "16px 0px 0px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentManagement = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", py: 2, px: 2, height: "100vh" }}>
      <TopTitle title={"Content Management"} />
      <Box
        sx={{
          padding: "10px 0px 0px 0px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="content management tabs"
        >
          <Tab
            label="Terms & Conditions"
            {...a11yProps(0)}
            sx={{
              textTransform: "capitalize",
            }}
          />
          <Tab
            label="Privacy Policy"
            {...a11yProps(1)}
            sx={{
              textTransform: "capitalize",
            }}
          />
          {/* <Tab
            label="About us"
            {...a11yProps(2)}
            sx={{
              textTransform: "capitalize",
            }}
          /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          <TermsConditions />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PrivacyPolicy />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <AboutUs />
        </TabPanel> */}
      </Box>
    </Box>
  );
};

export default ContentManagement;
