import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TableContainer from "../../Components/TableContainer";
import { ApiList } from "../../Config/ApiList";
import AxiosInstance from "../../Config/AxiosInstance";
import Profile from "../../Assets/profile1.jpg";
import Loader from "../../Components/Loader";
import { BASE_URL } from "../../Services/Constant";
const ReportUserList = () => {
  const location = useLocation();
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportFeedList, setReportFeedList] = useState([]);

  const fetchReportedList = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.REPORTED_FEED_DETAILS, {
        params: {
          feed_id: location?.state?.id,
        },
      });
      if (response && response?.data?.s === 1) {
        const formattedUsers = response?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
        }));
        setReportFeedList(formattedUsers);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchReportedList();
  }, []);
  const columns = [
    { field: "rowid", headerName: "Sr.No", width: 100 },
    {
      field: "profile_img",
      headerName: "Profile Image",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <img
              src={
                params && params?.row?.profile_img
                  ? `${BASE_URL}${params?.row?.profile_img}`
                  : Profile
              }
              alt="Profile"
              style={{ width: "30px", height: "30px", borderRadius: "5px" }}
            />
          </Box>
        )
      }
    },
    {
      field: "full_name",
      headerName: "Full name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "reason",
      headerName: "Reason Of Report",
      flex: 1,
      minWidth: 200,
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            pb: 2,
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "24px", xl: "35px" }, fontWeight: "600" }}
          >
            Reported Feed Details
          </Typography>
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TableContainer
              sx={{ height: { xs: "300px", md: "300px", xl: "600px" } }}
              rows={reportFeedList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReportUserList;
