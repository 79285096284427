export const ApiList = {
    //AUTH
    LOGIN:"/api/auth/login",
    FORGOT_PASSWORD:"/api/auth/forgot-password",
    GET_USER_LIST:"/api/user/get-all",
    REPORTED_FEED:"/api/feed/reported-feed",
    REPORTED_FEED_DETAILS:"/api/feed/reported-feed-details",
    DELETE_FEED:"/api/feed/remove",
    BLOCK_USER:"/api/user/account-status",
    GET_BUSINESS_CATEGORY:"/api/business/get-category",
    ADD_BUSINESS_CATEGORY:"/api/business/add-category",
    UPDATE_BUSINESS_CATEGORY:"/api/business/update-category",
    GET_FEED_CATEGORY:"/api/feed/get-category",
    ADD_FEED_CATEGORY:"/api/feed/add-category",
    UPDATE_FEED_CATEGORY:"/api/feed/update-category",
    GET_BUSINESS_LIST:"/api/business/get-all",
    UPDATE_BUSINESS:"/api/business/update",
    UPADTE_TERMS_CONDITIONS:"/api/content/update-terms-condition",
    GET_TERMS_CONDITIONS:"/api/content/get-terms-condition",
    UPDATE_PRIVACY_POLICY:"/api/content/update-privacy-policy",
    GET_PRIVACY_POLICY:"/api/content/get-privacy-policy",
    GET_ABOUT_US:"/api/content/get-aboutus",
    UPDATE_ABOUT_US:"/api/content/update-aboutus",
    GET_CONTACT_US:"/api/contact/get-all",
    GET_ADMIN_DASHBOARD:"/api/admin/analytics",
}